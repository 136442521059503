import { store } from "@/store/store";
import { mensajes_chat } from "@/shared/dtos/chat/mensajes_chat";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { mensajes_chatAllData } from "@/shared/dtos/chat/mensajes_chatAllData";

@Module({
  namespaced: true,
  name: "mensajes_chatModule",
  store,
  dynamic: true,
})
class mensajes_chatModule extends VuexModule {
  public mensajes_chats: mensajes_chat[] = [];
  public mensajes_chat: mensajes_chat = new mensajes_chat();
  public mensajes_chatsAllData: mensajes_chatAllData[] = [];
  public mesajes_not_read: number = 0;

  @Action({ commit: "onGetmensajes_chats" })
  public async getmensajes_chats() {
    return await ssmHttpService.get(API.mensajes_chat);
  }

  @Action({ commit: "onGetmensajes_chat" })
  public async getmensajes_chat(id: any) {
    return await ssmHttpService.get(API.mensajes_chat + "/" + id);
  }

  @Action({ commit: "onGetmensajes_chat_all_data" })
  public async getmensajes_chat_all_data(id: any) {
    return await ssmHttpService.get(API.mensajes_chat + "/incidencia/" + id);
  }

  @Action({ commit: "onGetmensajes_chat_all_data" })
  public async getmensajes_chat_all_data_backgound(id: any) {
    return await ssmHttpService.get(
      API.mensajes_chat + "/incidencia/" + id,
      null,
      false
    );
  }

  @Action({ commit: "onGetmensajes_chat_not_read" })
  public async getmensajes_chat_not_read_backgound(id_incidencia: number) {
    return await ssmHttpService.get(
      API.mensajes_chat + "/mensajes_no_leidos/" + id_incidencia,
      null,
      false
    );
  }

  @Action
  public async guardarmensajes_chat(mensajes_chat: mensajes_chat) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.mensajes_chat,
      mensajes_chat.toJson(),
      false
    );
  }

  @Action
  public async modificarmensajes_chat(mensajes_chat: mensajes_chat) {
    return await ssmHttpService.put(
      API.mensajes_chat + "/" + mensajes_chat.id,
      mensajes_chat
    );
  }

  @Action
  public async eliminarmensajes_chat(mensajes_chat: mensajes_chat) {
    return await ssmHttpService.delete(
      API.mensajes_chat + "/" + mensajes_chat.id,
      null,
      false
    );
  }

  @Mutation
  public onGetmensajes_chat_all_data(res: mensajes_chatAllData[]) {
    this.mensajes_chatsAllData = res
      ? res.map((x) => new mensajes_chatAllData(x))
      : [];
  }

  @Mutation
  public onGetmensajes_chats(res: mensajes_chat[]) {
    this.mensajes_chats = res ? res.map((x) => new mensajes_chat(x)) : [];
  }

  @Mutation
  public onGetmensajes_chat(res: mensajes_chat) {
    this.mensajes_chat = new mensajes_chat(res);
  }

  @Mutation
  public onGetmensajes_chat_not_read(res: number) {
    this.mesajes_not_read = res;
  }
}
export default getModule(mensajes_chatModule);
