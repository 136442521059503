


















import mensajes_chatModule from "@/store/modules/mensajes_chat-module";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    IconoChat: () => import("@/components/Chat/icono_chat.vue"),
    ChatOpen: () => import("@/components/Chat/chat_open.vue"),
  },
})
export default class HandlerChat extends Vue {
  @PropSync("show", { default: false }) show_chat!: boolean;
  @Prop({ default: "Chat" }) titulo!: string;
  @Prop({ default: "Conversación del chat" }) subtitulo!: string;
  @Prop({ default: 0 }) id_chat!: number;

  public intelvalo = 0;
  created() {}
  mounted() {
    this.intelvalo = setInterval(() => {
      this.get_mensajes_chat();
    }, 5000);
  }
  get number_of_mesajes() {
    this.$emit("number_mensajes", mensajes_chatModule.mesajes_not_read);
    return mensajes_chatModule.mesajes_not_read;
  }
  @Watch("show_chat")
  WShwChat() {
    if (this.show_chat) {
      this.$emit("number_mensajes", 0);
    }
  }
  get_mensajes_chat() {
    mensajes_chatModule.getmensajes_chat_not_read_backgound(this.id_chat);
  }

  destroyed() {
    clearInterval(this.intelvalo);
  }
}
